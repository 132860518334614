/* react-boostrap-carousel.css*/
@charset "UTF-8";

.carousel .carousel-inner .item,
.carousel .carousel-inner .carousel-item {
  overflow: hidden;
}

.carousel-control.right,
.carousel-control-next {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0.0001)),
    to(rgba(0, 0, 0, 0))
  );
}

.carousel-control.left,
.carousel-control-prev {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0.0001)),
    to(rgba(0, 0, 0, 0))
  );
}

.carousel-control.right:hover,
.carousel-control-next:hover {
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.0001) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0.0001)),
    to(rgba(0, 0, 0, 0.5))
  );
}

.carousel-control.left:hover,
.carousel-control-prev:hover {
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.0001) 100%
  );
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0.5)),
    to(rgba(0, 0, 0, 0.0001))
  );
}

.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
  transition-timing-function: ease-in;
  transition-duration: 0.6s;
}

.carousel-fade .carousel-inner .active {
  opacity: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item-next {
  opacity: 0;
}

.carousel-fade .carousel-control {
  z-index: 2;
}

.carousel-control,
.carousel-control-prev,
.carousel-control-next {
  width: 50px;
  cursor: pointer;
}

.carousel-indicators .active {
  background-color: #000;
}

.carousel-indicators li {
  background-color: #747272;
  border: 0px solid;
}

.controlsHidden .carousel-control,
.controlsHidden .carousel-control-prev,
.controlsHidden .carousel-control-next {
  display: none;
}

.controlsHidden .carousel:hover .carousel-control {
  display: inline-block;
}

.carousel-center {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.leftControl > i,
.leftControl > span {
  left: 50%;
  margin-left: -10px;
  position: absolute;
  color: #fff;
  top: 50%;
  z-index: 5;
  display: inline-block;
  margin-top: -10px;
  font-size: 25px;
}

.rightControl > i,
.rightControl > span {
  right: 50%;
  margin-right: -10px;
  position: absolute;
  color: #fff;
  top: 50%;
  z-index: 5;
  display: inline-block;
  margin-top: -10px;
  font-size: 25px;
}

@font-face {
  font-family: "react-bootstrap-carousel";
  src: url("../fonts/react-bootstrap-carousel.eot");
  src: url("../fonts/react-bootstrap-carousel.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/react-bootstrap-carousel.woff") format("woff"),
    url("../fonts/react-bootstrap-carousel.ttf") format("truetype"),
    url("../fonts/react-bootstrap-carousel.svg#react-bootstrap-carousel")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "react-bootstrap-carousel" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "react-bootstrap-carousel" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-left:before {
  content: "\61";
}
.icon-chevron-right:before {
  content: "\62";
}
